<div class="top-welcome">
    <div class="row base-1">
        <div class="col-12">
            <div class="row base-2">
                <div class="col-12">
                    <div class="banner-welcome">
                        <div class="title">
                            {{ translations[0][language.toLowerCase()] }}
                        </div>
                        <div class="description">
                            <span style="font-family: 'acrom-regular', 'arial', sans-serif;">{{ translations[21][language.toLowerCase()] }}</span> {{ translations[14][language.toLowerCase()] }}
                            <span style="font-family: 'acrom-regular', 'arial', sans-serif;">{{ translations[13][language.toLowerCase()] }}</span>
                        </div>
                        <div class="container-btn-pf-blue">
                            <button (click)="scrollToForm(contactus)" class="btn btn-pf-blue">{{translations[1][language.toLowerCase()]}}</button>
                        </div>
                    </div>
                    <div class="arrow-red-down" (click)="scrollToForm(tripled)">
                        <img src="./assets/images/arrow-down-red.svg" alt="">
                    </div>
                </div>
                <div class="geometric-form-pf-img">
                    <img src="./assets/images/mid-circle-red.svg" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="row m-0 container-playful-hub" style="display: none;">
        <div class="col-12 p-0 subcontainer-playful-hub">
            <div class="row m-0" style="background: #0000FF;">
                <div class="col-md-4 col-12 p-0">
                    <img src="./assets/images/img-pf-hub.png" alt="">
                </div>
                <div class="col-md-4 col-12">
                    <div class="container-vertical">
                        <div class="txt-hub-welcome">
                            <div class="title-txt-hub">{{translations[2][language.toLowerCase()]}}</div>
                            <p class="description-txt-hub">{{translations[3][language.toLowerCase()]}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12" style="margin: auto;text-align: center;">
                    <button (click)="visitPlayfulHub()" class="btn btn-pf-blue btn-pf-blue-hub">{{translations[4][language.toLowerCase()]}}</button>
                </div>
            </div>
        </div>
    </div>
    <div #tripled class="row m-0 container-triple-d">
        <div class="col-12" style="max-width: 1280px; margin: 0 auto;">
            <div class="row">
                <div class="col-lg-6 col-12 triple-d">
                    <div class="container-text-triple-d">
                        <div class="row">
                            <div class="col-12 subtitle">
                                {{translations[5][language.toLowerCase()]}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 title">
                                {{translations[6][language.toLowerCase()]}}
                                <hr class="hr-title">
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-12 text">
                                {{translations[22][language.toLowerCase()]}}
                                <b>{{translations[23][language.toLowerCase()]}}</b> {{translations[24][language.toLowerCase()]}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-12 sphere-text">
                    <img *ngIf="language == 'ES'" src="./assets/images/cx-bi-strategy.svg" alt="">
                    <img *ngIf="language == 'EN'" src="./assets/images/cx-bi-strategy-en.svg" alt="">
                </div>
            </div>
        </div>
    </div>
    <br><br><br><br><br><br>
    <div class="row m-0" style="background: #0000FF;">
        <div class="col-12 p-0 h-100" style="max-width: 1280px; margin: 0 auto;">
            <div class="row m-0 h-100">
                <div class="col-md-6 col-12 p-0">
                    <div class="geometric-form-welcome-red">
                        <div class="container-text-cap d-md-none" style="position:relative;">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 subtitle">
                                        {{translations[8][language.toLowerCase()]}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 title">
                                        {{translations[9][language.toLowerCase()]}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 p-0">
                                        <button routerLink="/servicios" class="btn btn-pf-blue btn-geometric-form-black">{{translations[10][language.toLowerCase()]}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="geometric-form-welcome-black">
                        <div class="container-text-cap d-none d-md-block" style="position:relative;">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 subtitle">
                                        {{translations[8][language.toLowerCase()]}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 title">
                                        {{translations[9][language.toLowerCase()]}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <button routerLink="/servicios" class="btn btn-pf-blue btn-geometric-form-black">{{translations[10][language.toLowerCase()]}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-12 container-navs-cap" style="z-index: 1;">
                    <ul ngbNav #nav="ngbNav" class="nav-tabs border-0 mt-lg-5">
                        <li ngbNavItem>
                            <a ngbNavLink>CX</a>
                            <ng-template ngbNavContent>
                                <div class="subtitle-navs">CX</div>
                                <div class="title-navs">{{translations[27][language.toLowerCase()]}} <span> {{translations[36][language.toLowerCase()]}}</span></div>
                                <div class="text-navs">{{translations[28][language.toLowerCase()]}}</div>
                            </ng-template>
                        </li>
                        <li ngbNavItem>
                            <a ngbNavLink>UX</a>
                            <ng-template ngbNavContent>
                                <div class="subtitle-navs">UX</div>
                                <div class="title-navs">{{translations[29][language.toLowerCase()]}} <span>{{translations[30][language.toLowerCase()]}}</span></div>
                                <div class="text-navs">{{translations[31][language.toLowerCase()]}}</div>
                            </ng-template>
                        </li>
                        <li ngbNavItem>
                            <div *ngIf="language == 'ES'">
                                <a ngbNavLink>TD</a>
                            </div>
                            <div *ngIf="language == 'EN'">
                                <a ngbNavLink>DT</a>
                            </div>
                            <ng-template ngbNavContent>
                                <div *ngIf="language == 'ES'">
                                    <div class="subtitle-navs">TD</div>
                                    <div class="title-navs">{{translations[32][language.toLowerCase()]}} <span>{{translations[33][language.toLowerCase()]}}</span></div>
                                </div>
                                <div *ngIf="language == 'EN'">
                                    <div class="subtitle-navs">DT</div>
                                    <div class="title-navs">DIGITAL <span>TRANSFORMATION</span></div>
                                </div>
                                <div class="text-navs">{{ translations[34][language.toLowerCase()] }}</div>
                            </ng-template>
                        </li>
                        <li ngbNavItem>
                            <a ngbNavLink>PL</a>
                            <ng-template ngbNavContent>
                                <div class="subtitle-navs">PL</div>
                                <div class="title-navs" style="width: auto; height: auto;">{{translations[25][language.toLowerCase()]}} <span>{{translations[26][language.toLowerCase()]}}</span></div>
                                <div class="text-navs">{{translations[35][language.toLowerCase()]}}</div>
                            </ng-template>
                        </li>
                    </ul>

                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div #contactus class="row m-0">
    <div class="col-12 p-0" style="max-width: 1280px; margin: 0 auto;">
        <app-contactus></app-contactus>
    </div>
</div>
<app-loader *ngIf="loading"></app-loader>
<!-- <ngx-spinner
  bdColor="rgba(14, 17, 22, 1)"
  template="<img style='width: 30em;' src='.\assets\icons\Progress-bar_transparente.gif' />"
>
</ngx-spinner> -->
