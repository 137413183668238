import { Component, OnInit } from '@angular/core';
import { TranslationsService } from '../translations/translations.service';
import { NavbarComponent } from '../includes/navbar/navbar.component';

@Component({
  selector: 'app-briefcase',
  templateUrl: './briefcase.component.html',
  styleUrls: ['./briefcase.component.css']
})
export class BriefcaseComponent implements OnInit {

  translations:any = [];
  language:string;
  loading: boolean = true;

  public array_wow = [
    {name: "santander", url_logo: "./assets/icons/wow/logo-santander.svg"},
    {name: "nemak", url_logo: "./assets/icons/wow/logo-nemak.svg"},
    {name: "afirme", url_logo: "./assets/icons/wow/logo-afirme.svg"},
    {name: "oxxo", url_logo: "./assets/icons/wow/logo-oxxo.svg"},
    {name: "stanley", url_logo: "./assets/icons/wow/logo-stanley.svg"},
    {name: "home_depot", url_logo: "./assets/icons/wow/logo-home-depot.svg"},
    {name: "heb", url_logo: "./assets/icons/wow/logo-heb.svg"},
    {name: "mary_kay", url_logo: "./assets/icons/wow/logo-mary-kay.svg"},
    {name: "cemex", url_logo: "./assets/icons/wow/logo-cemex.svg"},
    {name: "banorte", url_logo: "./assets/icons/wow/logo-banorte.svg"},
    {name: "berel", url_logo: "./assets/icons/wow/logo-berel.svg"},
    {name: "banco_base", url_logo: "./assets/icons/wow/logo-bancobase.svg"},
    {name: "superboletos", url_logo: "./assets/icons/wow/logo-superBoletos.svg"}
  ]

  public array_testimony = [
    { 
      empresa: "Mary Kay", 
      logo: "./assets/icons/wow/logo-mary-kay-black.svg", 
      puesto: "ANALISTA DE NEGOCIOS",
      puesto_en: "BUSINESS ANALYST",
      comentario: "Una de las principales fortalezas del equipo de Playful es la creatividad y la manera de integrar a sus expertos al momento de hacer las propuestas. La confianza generada, la calidad del trabajo, y el éxito del producto son algunas razones por las que volvería a contratar sus servicios.",  
      comentario_en: "One of the main strengths of the Playful team is the creativity and the involvement of their experts when making proposals. The trust generated, the quality of the work, and the success of the product are some reasons why I would hire their services again."  
    },
    { 
      empresa: "OXXO", 
      logo: "./assets/icons/wow/logoOXXO-testimonial.png", 
      puesto: "IT INNOVATION",
      puesto_en: "IT INNOVATION",
      comentario: "Trabajar con Playful es sinónimo de garantía de satisfacción, acompañada de claros entendimientos, comunicación continua y calidad en entregables. En cada momento me hicieron sentir segura de que estaba trabajando con el aliado indicado.",  
      comentario_en: "Working with Playful is synonymous of guaranteed satisfaction, accompanied by clear understanding, continuous communication, and quality deliverables. At every moment they made me feel sure that I was working with the right ally."
    },
    { 
      empresa: "Berel", 
      logo: "./assets/icons/wow/testimonial-berel.png", 
      puesto: "EJECUTIVO DE MARKETING DIGITAL",
      puesto_en: "DIGITAL MARKETING EXECUTIVE", 
      comentario: "Dentro de las fortalezas puedo mencionar la innovación y estrategia con la que se trabaja en cada proyecto, cubriendo las necesidades del cliente.",
      comentario_en: "Among the strengths I can mention are the innovation and strategy with which each project is handled, meeting the needs of the client."
    },
    { 
      empresa: "WAC DE MÉXICO", 
      logo: "./assets/icons/wow/testimonial-wac.png", 
      puesto: "MERCADOTECNIA",
      puesto_en: "MARKETING",
      comentario: "Playful es una consultora divertida, con experiencia y bien estructurada que consideramos parte de WAC de México.",
      comentario_en: "Playful is a fun, experienced and well structured agency that we consider part of WAC de México."
    }
  ]


  constructor(
    private translationsService : TranslationsService,
    private navbarComponent : NavbarComponent) { }

  ngOnInit(): void {

    window.scrollTo(0, 0);

    if(localStorage.getItem("lang") != null) {
      this.language = localStorage.getItem("lang");
    }else{
      this.language = this.navbarComponent.langSelected;
    }
    // console.log(this.language);
    this.view();
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  }

  openModalVideo(codeYoutube:string){
    let langYoutube = 'es';
    if(this.language == 'EN'){
      langYoutube = 'en';
    }
    document.getElementById("video").setAttribute('src', 'https://www.youtube.com/embed/'+codeYoutube+'?hl='+langYoutube+'&amp;cc_lang_pref='+langYoutube+'&amp;cc=1');
  }

  scrollToCase(el: HTMLElement) {
    el.scrollIntoView({behavior:"smooth"});
  }

  view(){
    this.translationsService.getone("3").subscribe(
      res => {
        // console.log(res);
        this.translations = res['body'];
      },
      err =>{
        console.log(err);
      }
    )
  }

  // async view(){
  //   this.translations = [];
  //   var answer = await this.translationsService.getone("3");
  //   console.log(answer);
    
  //   if(answer['status'] == "200"){
  //     this.translations = answer['body']; 
  //   }
  // }

  close_iframe(){
    document.getElementById("video").setAttribute('src', '');
  }

}
