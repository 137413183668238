import { Component, OnInit } from '@angular/core';
import { TranslationsService } from '../translations/translations.service';
import { NavbarComponent } from './../includes/navbar/navbar.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  translations:any = [];
  language:string;
  loading: boolean = true;

  constructor(
    private translationsService : TranslationsService,
    private navbarComponent : NavbarComponent) {
  }
  
  ngOnInit() {
    window.scrollTo(0, 0);    
    if(localStorage.getItem("lang") != null) {
      this.language = localStorage.getItem("lang");
    }else{
      this.language = this.navbarComponent.langSelected;
    }
    this.view();
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  }

  scrollToForm(el: HTMLElement){
    el.scrollIntoView({behavior:"smooth"});
  }

  visitPlayfulHub (){
    var newTab = window.open("https://expotourvirtual.com/playfulo2c/", "_blank");
    newTab.location;
  }

  view(){
    this.translationsService.getone("1").subscribe(
      res => {
        this.translations = res['body'];
      },
      err =>{
        console.log(err);
      }
    )
  }

  // async view(){
  //   this.translations = [];
  //   var answer =  await this.translationsService.getone("1");
  //   console.log(answer);
  //   if(answer['status'] == "200"){
  //     this.translations = answer['body'];
  //   }
  // }
}
