import { Component, OnInit } from '@angular/core';
import { LocationsService } from '../../locations/locations.service';
import { ProfilesService } from '../../profiles/profiles.service';

import { EmailDto } from '../../dto/email-dto';
import { ContactusService } from './contactus.service';
import { NavbarComponent } from '../navbar/navbar.component';
import { TranslationsService } from '../../translations/translations.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  translations:any = [];
  language:string;

  senemail: any;

  locations: string = "";
  profiles: string = "";
  name: string = "";
  lastname: string = "";
  email: string = "";
  phone: string = "";
  textbody: string = "";

  emailprofile: string = "";
  subject: string = "";

  locationslist: any = [];
  profileslist: any = [];

  nameflag: boolean = false;
  lastnameflag: boolean = false;
  emailflag: boolean = false;
  locationsflag: boolean = false;
  phoneflag: boolean = false;
  profilesflag: boolean = false;
  textbodyflag: boolean = false;


  infosuccess: boolean = false;

  constructor(
    private locationsService: LocationsService,
    private profilesService: ProfilesService,
    private contactusService: ContactusService,
    private translationsService : TranslationsService,
    private navbarComponent : NavbarComponent
  ) { }

  ngOnInit(): void {
    this.viewlocations();
    this.viewprofiles();
    this.locations = null;
    this.profiles = null;

    if(localStorage.getItem("lang") != null) {
      this.language = localStorage.getItem("lang");
    }else{
      this.language = this.navbarComponent.langSelected;
    }
    this.view();
  }

  view(){
    this.translationsService.getone("5").subscribe(
      res => {
        // console.log(res);
        this.translations = res['body'];
      },
      err =>{
        console.log(err);
      }
    )
  }

  // async view(){
  //   this.translations = [];
  //   var answer = await this.translationsService.getone("5");
  //   console.log(answer);
    
  //   if(answer['status'] == "200"){
  //     this.translations = answer['body']; 
  //   }
  // }


  async viewlocations() {
    this.locationslist = [];
    var answer = await this.locationsService.getactive();
    if (answer['status'] == "200") {
      this.locationslist = answer['body'];
    }
  }

  async viewprofiles() {
    this.profileslist = [];
    var answer = await this.profilesService.getactive();
    if (answer['status'] == "200") {
      this.profileslist = answer['body'];
      if(this.language == 'EN'){
        this.profileslist[0].name = "I'm interested in Playful services";
        this.profileslist[1].name = "I'm a supplier";
        this.profileslist[2].name = "I want to be part of the team";
      }
      console.log(this.profileslist);
    }
  }

  async typeprofile() {
    for (var i = 0; i < this.profileslist.length; i++) {
      if (this.profileslist[i]['id'] == this.profiles) {
        this.emailprofile = this.profileslist[i]['emails'];
        this.subject = this.profileslist[i]['name'];
        break;
      }
    }
  }

  async mailto() {

    this.nameflag = false;
    this.lastnameflag = false;
    this.emailflag = false;
    this.locationsflag = false;
    this.phoneflag = false;
    this.profilesflag = false;
    this.textbodyflag = false;

    this.infosuccess = false;

    if (this.name == "") {
      this.nameflag = true;
    }

    if (this.lastname == "") {
      this.lastnameflag = true;
    }

    if (this.email == "") {
      this.emailflag = true;
    }

    if (this.locations == "") {
      this.locationsflag = true;
    }

    if (this.phone == "") {
      this.phoneflag = true;
    }

    if (this.profiles == "") {
      this.profilesflag = true;
    }

    if (this.textbody == "") {
      this.textbodyflag = true;
    }

    if (!this.nameflag && !this.lastnameflag && !this.emailflag && !this.locationsflag && !this.phoneflag && !this.profilesflag && !this.textbodyflag) {
      var motivo = this.profiles == "3" ? 'Alguien quiere formar parte de nosotros ' : this.profiles == "2" ? 'Nuestro compañero proveedor ' : 'Nuestro futuro cliente ';

      let emailDto = new EmailDto;
      emailDto.email = this.email;
      emailDto.emailprofile = this.emailprofile;
      emailDto.subject = motivo;
      emailDto.phone = this.phone;
      emailDto.name = this.name + ' ' + this.lastname;
      emailDto.locations = this.locations;
      emailDto.textbody = this.textbody;

      var answer = await this.contactusService.sendmail(emailDto);
      if (answer['status'] == "200") {
        //alert("Se ha enviado con exito tu información");
        this.infosuccess = true;
        this.locations = "";
        this.profiles = "";
        this.name = "";
        this.lastname = "";
        this.email = "";
        this.phone = "";
        this.textbody = "";
      }
    }
  }
}
