import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'portalplayful';
  location: Location;
  loading = true;

  constructor(){}

  ngOnInit() {
    setTimeout(() => {
      this.loading = false;
    }, 2000);
    // if (location.protocol === 'http:') {
    //   window.location.href = location.href.replace('http', 'https');
    // }
    
    var language = navigator.language;
    if(localStorage.getItem("lang") == null){
      if(language.includes("es")){
        localStorage.setItem("lang","ES");
      }else{
        localStorage.setItem("lang","EN");
      }
    }
  }
}
